import { localeList } from "~/utils/locale";
import { performSearch } from '~/utils/searchUtil';

const initialState = {
  id: undefined,
  product: undefined,
  addons: {},
  donations: {},
  vase: {},
  greet: {},
  subsAddon: {},
  recipient: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    postalCode: "",
    deliveryNote: "",
    buzzerCode:"",
    relationship: "",
    businessName:"",
  },
  deliveryWindow: {},
  contactDetails: {},
  card: {},
  cardMessages: {},
  cardSignatures: {},
  cardMessagesWithSignature: {},
  subsDeliveryDates: {},
  subscriptionTimePeriod: undefined,
  subscriptionDates: [],
  frequency: 0,
  weather: {},
  locale: undefined,
  sameDayDelivery: {
    isSameAddressOrder: false,
    currSameAddressOrder: null,
    toggleYes: null
  },
  citySearchResult: [],
  deliveryTown: {},
  deliveryDate: '',
  occasion: "",
  subscriptionProd: {},
  selectedSubscription: {
    collectionType: "",
    variantType: "",
    numOfDeliveries: 0,
  },
  cutoffSessionStart: "",
  selectedSellingPlan: ""
};

export const state = () => ({
  product: undefined,
  addons: {},
  donations: {},
  vase: {},
  greet: {},
  subsAddon: {},
  recipient: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    postalCode: "",
    deliveryNote: "",
    apartment: "",
    buzzerCode: "",
    relationship: "",
    province: "",
    addressType: "",
    apartment: "",
    businessName:"",
  },
  deliveryWindow: {},
  contactDetails: {},
  card: {},
  cardMessages: {},
  cardSignatures: {},
  cardMessagesWithSignature: {},
  subsDeliveryDates: {},
  subscriptionTimePeriod: undefined,
  subscriptionDates: [],
  frequency: 0,
  weather: {},
  locale: undefined,
  sameDayDelivery: {
    isSameAddressOrder: false,
    currSameAddressOrder: null,
    toggleYes: null
  },
  citySearchResult: [],
  deliveryTown: {},
  deliveryDate: '',
  occasion: "",
  subscriptionProd: {},
  selectedSubscription: {
    collectionType: "",
    variantType: "",
    numOfDeliveries: 0,
  },
  cutoffSessionStart: "",
  selectedSellingPlan: ""
});

/**
 * Cart Actions
 */
export const actions = {
  PRODUCT_SET({ state, commit }, event) {
    commit("setProduct", event);
  },
  SUBSCRIPTION_SET({ state, commit }, event) {
    commit("setSubscription", event);
  },
  SUB_COLLECTION_SET({ state, commit }, event) {
    commit("setSubCollection", event);
  },
  SUB_COLLECTION_RESET({ state, commit }) {
    commit("resetSubCollection");
  },
  SUB_VARIANT_SET({ state, commit }, event) {
    commit("setSubVariant", event);
  },
  SUB_VARIANT_RESET({ state, commit }) {
    commit("resetSubVariant");
  },
  SUB_NUM_DELIVERIES_SET({ state, commit }, event) {
    commit("setSubNumDeliveries", event);
  },
  ADDON_UPDATE({ state, commit }, addon) {
    commit("setAddons", addon);
  },
  ADDON_REMOVE({ state, commit }, addon) {
    commit("removeAddon", addon);
  },
  DONATIONS_UPDATE({ state, commit }, donation) {
    commit("setDonations", donation);
  },
  DONATIONS_REMOVE({ state, commit }, donation) {
    commit("removeDonations", donation);
  },
  VASE_ADD({ state, commit }, vase) {
    commit("setVase", vase);
  },
  VASE_REMOVE({ state, commit }, vase) {
    commit("removeVase", vase);
  },
  GREET_ADD({ state, commit }, greet) {
    commit("setGreet", greet);
  },
  GREET_REMOVE({ state, commit }, greet) {
    commit("removeGreet", greet);
  },
  RECIPIENT_ADD({ state, commit }, recipient) {
    commit("setRecipient", recipient);
  },
  RECIPIENT_REMOVE({ state, commit }, recipient) {
    commit("removeRecipient", recipient);
  },
  DELIVERY_WINDOW_ADD({ state, commit }, deliveryWindow) {
    commit("setDeliveryWindow", deliveryWindow);
  },
  CONTACT_DETAIL_ADD({ state, commit }, contactDetails) {
    commit("setContactDetails", contactDetails);
  },
  CARD_ADD({ state, commit }, card) {
    commit("setCard", card);
  },
  CARD_MESSAGES_ADD({ state, commit }, cardMessages) {
    commit("setCardMessages", cardMessages);
  },
  MULTIPLE_CARD_MESSAGES_ADD({ state, commit }, cardMessages) {
    commit("setMultipleCardMessages", cardMessages);
  },
  CARD_SIGNATURE_ADD({ state, commit }, cardSignatures) {
    commit("setCardSignature", cardSignatures);
  },
  CARD_WITH_SIGNATURE_ADD({ state, commit }, cardMessagesWithSignature) {
    commit("setMessagesWithSignature", cardMessagesWithSignature);
  },
  MULTIPLE_CARD_SIGNATURE_ADD({ state, commit }, cardSignatures) {
    commit("setMultipleCardSignature", cardSignatures);
  },
  MESSAGE_TEMPLATES_ADD({ state, commit }, selectedTemplates) {
    commit("setMsgTemplate", selectedTemplates);
  },
  CARD_DELIVERY_NOTE_UPDATE({ state, commit }, deliveryNote) {
    commit("setSubsOngoingCardDeliveryNote", deliveryNote);
  },
  CARD_FREQUENCY_UPDATE({ state, commit }, frequency) {
    commit("setSubsOngoingCardFrequency", frequency);
  },
  SUBS_DELIVERY_DATES_ADD({ state, commit }, subsDeliveryDates) {
    commit("setSubsDeliveryDates", subsDeliveryDates);
  },
  SUBS_TIME_PERIOD_SET({ state, commit }, subscriptionTimePeriod) {
    commit("setSubsTimePeriod", subscriptionTimePeriod);
  },
  SUBS_DATES_SET({ state, commit }, dates) {
    commit("setSubsDates", dates);
  },
  SUBS_FREQUENCY_SET({ state, commit }, frequency) {
    commit("setSubsFrequency", frequency);
  },
  CART_ITEM_SET({ state, commit }, cartItem) {
    commit("setCartItem", cartItem);
  },
  CART_ITEM_FLUSH({ state, commit }) {
    commit("flushCartItem");
  },
  UPDATE_DELIVERY_DATE({ state, commit }, deliveryDate) {
    commit("setDeliveryDate", deliveryDate);
  },
  //This custom action which use only for replace sku name for curated bundles name ends with _ca
  REPLACE_PRODUCT_SKU_TO_MT({ state, commit }) {
    commit("replaceCuratedBundlesSKU");
  },
  ADD_WEATHER({ state, commit }, weather) {
    commit("setWeather", weather);
  },
  UPDATE_DELIVERY_TOWN({ state, commit }, deliveryTown) {
    commit("setDeliveryTown", deliveryTown)
    commit("setCutoffSessionStart")
  },
  SET_RELATIONSHIP({ state, commit }, relationship) {
    commit("setRelationship", relationship)
  },
  SET_OCCASION({ state, commit }, occasion) {
    commit("setOccasion", occasion)
  },
  SET_IS_SAME_ADDRESS_ORDER({ state, commit }, isSameAddressOrder) {
    commit("setIsSameAddressOrder", isSameAddressOrder)
  },
  SET_CURR_SAME_ADDRESS_ORDER({ state, commit }, currSameAddressOrder) {
    commit("setCurrSameAddressOrder", currSameAddressOrder)
  },
  SET_CURR_SAME_ADDRESS_TOGGLE({ state, commit }, sameAddressToggle) {
    commit("setSameAddressToggle", sameAddressToggle)
  },
  async FETCH_SUGGESTIONS({ state, commit }, query) {
    const citySearchResult = await performSearch(query)
    commit('setCitySearchResult', citySearchResult)
    return state.citySearchResult;
  },
  SET_SELLING_PLAN({ state, commit }, sellingPlan) {
    commit("setSelectedSellingPlan", sellingPlan)
  },
};

export const mutations = {
  setProduct(state, { product, locale, deliveryTown, id }) {
    // TODO refactor to nanoid ??
    state.id = id && id.length > 0 ? id : "_" + Math.random().toString(36).substr(2, 9);
    state.product = product;
    state.locale = locale;
    state.deliveryTown = deliveryTown;
    // state.region = region;
    // state.province = province

  },
  setSubscription(state, subscriptionProd) {
    state.subscriptionProd = subscriptionProd;
  },
  setSubNumDeliveries(state, numOfDeliveries) {
    state.selectedSubscription.numOfDeliveries = numOfDeliveries;
  },
  setSubCollection(state, collectionType) {
    state.selectedSubscription.collectionType = collectionType;
  },
  resetSubCollection(state) {
    state.selectedSubscription.collectionType = "";
  },
  setSubVariant(state, variantType) {
    state.selectedSubscription.variantType = variantType;
  },
  resetSubVariant(state) {
    state.selectedSubscription.variantType = "";
  },
  setCutoffSessionStart(state) {
    const currentDate = this.$dayjs().tz("America/Winnipeg");
    const formattedDate = currentDate.format('YYYY-MM-DDTHH:mm:ss');
    state.cutoffSessionStart = formattedDate;
  },
  setAddons(state, addon) {
    state.addons[addon.title] = addon;
  },
  removeAddon(state, addon) {
    delete state.addons[addon.title];
  },
  setDonations(state, donation) {
    state.donations[donation.index] = donation;
  },
  removeDonations(state, donation) {
    delete state.donations[donation.index];
  },
  setVase(state, vase) {
    state.vase = vase;
  },
  removeVase(state, vase) {
    state.vase = {};
  },
  setGreet(state, greet) {
    state.greet = greet;
  },
  removeGreet(state, greet) {
    state.greet = {};
  },
  setRecipient(state, recipient) {
    state.recipient = recipient;
  },
  removeRecipient(state, recipient) {
    state.recipient = {};
  },
  setDeliveryWindow(state, deliveryWindow) {
    state.deliveryWindow = deliveryWindow;
  },
  setContactDetails(state, contactDetails) {
    state.contactDetails = contactDetails;
  },
  setCard(state, card) {
    state.card = card;
  },
  setCardMessages(state, cardMessages) {
    state.cardMessages = cardMessages;
  },
  setMultipleCardMessages(state, cardMessages) {
    state.card.cardMessages = cardMessages;
  },
  setCardSignature(state, cardSignatures) {
    state.cardSignatures = cardSignatures;
  },
  setMultipleCardSignature(state, cardSignatures) {
    state.card.cardSignatures = cardSignatures;
  },
  setMessagesWithSignature(state, cardMessagesWithSignature) {
    state.cardMessagesWithSignature = cardMessagesWithSignature;
  },
  setMsgTemplate(state, selectedTemplates) {
    state.card.selectedTemplates = selectedTemplates;
  },
  setSubsOngoingCardDeliveryNote(state, deliveryNote) {
    state.card.deliveryNote = deliveryNote;
  },
  setSubsOngoingCardFrequency(state, frequency) {
    state.card.frequency = frequency;
  },
  setSubsDeliveryDates(state, subsDeliveryDates) {
    state.subsDeliveryDates = subsDeliveryDates;
  },
  setSubsTimePeriod(state, subscriptionTimePeriod) {
    state.subscriptionTimePeriod = subscriptionTimePeriod;
  },
  setSubsDates(state, dates) {
    state.subscriptionDates = dates;
  },
  setSubsFrequency(state, frequency) {
    state.frequency = frequency;
  },
  setCartItem(state, cartItem) {
    Object.keys(initialState).map((key) => {
      state[key] = cartItem[key];
    });
  },
  flushCartItem(state) {
    Object.keys(initialState).map((key) => {
      state[key] = initialState[key];
    });
    state.addons = {};
    state.donations = {};
    state.subsAddon = {};

  },
  replaceCuratedBundlesSKU(state) {
    const newSKU = state.product.sku.replace("_ca", "_mt");
    state.product.sku = newSKU;
  },
  setWeather(state, weather) {
    state.weather = weather;
  },
  setRelationship(state, relationship) {
    state.recipient.relationship = relationship;
  },
  setOccasion(state, occasion) {
    state.occasion = occasion;
  },
  setIsSameAddressOrder(state, isSameAddressOrder) {
    state.sameDayDelivery.isSameAddressOrder = isSameAddressOrder;
  },
  setCurrSameAddressOrder(state, currSameAddressOrder) {
    state.sameDayDelivery.currSameAddressOrder = currSameAddressOrder;
  },
  setSameAddressToggle(state, sameAddressToggle) {
    state.sameDayDelivery.toggleYes = sameAddressToggle;
  },
  setDeliveryDate(state, deliveryDate) {
    state.deliveryDate = deliveryDate;
  },
  setDeliveryTown(state, deliveryTown) {
    state.deliveryTown = deliveryTown
  },
  setCitySearchResult(state, citySearchResult) {
    state.citySearchResult = citySearchResult;
  },
  setSelectedSellingPlan(state, sellingPlan) {
    state.selectedSellingPlan = sellingPlan;
    if (state.product) {
      state.product.selectedSellingPlan = sellingPlan;
    }
  }
};
